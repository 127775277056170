// production defaults
let WEB_HOSTNAME = 'cargo.site';
let CRDT_SERVER_ORIGIN = 'wss://w.s.cargo.site';
let HOMEPAGE_ORIGIN = 'https://u.cargo.site';
let API_ORIGIN = 'https://api.cargo.site/v1';
let C2_API_ORIGIN = 'https://cargo.site/_api'
let AUTH_ORIGIN = 'https://cargo.site';
let API_PROXY_ORIGIN = 'https://cargo.site/_api/v1-p';
let INTERCOM_APP_ID = 'yjhpd2ug';

switch (CARGO_ENV) {

	case 'localhost':
	case 'local-ssr':

		WEB_HOSTNAME = 'local.dev.cargo.site';
		CRDT_SERVER_ORIGIN = 'wss://ws.dev.cargo.site';
		HOMEPAGE_ORIGIN = 'https://local.dev.cargo.site';
		API_ORIGIN = 'https://api.dev.cargo.site/v1';
		C2_API_ORIGIN = 'https://dev.cargo.site/_api';
		AUTH_ORIGIN = 'https://dev.cargo.site';
		API_PROXY_ORIGIN = 'https://dev.cargo.site/_api/v1-p';
		INTERCOM_APP_ID = 'na91uuyv';

	break;
	case 'local-crdt':

		WEB_HOSTNAME = 'local.dev.cargo.site';
		CRDT_SERVER_ORIGIN = 'wss://ws.local.dev.cargo.site:4443';
		HOMEPAGE_ORIGIN = 'https://local.dev.cargo.site';
		API_ORIGIN = 'https://api.dev.cargo.site/v1';
		C2_API_ORIGIN = 'https://dev.cargo.site/_api';
		AUTH_ORIGIN = 'https://dev.cargo.site';
		API_PROXY_ORIGIN = 'https://dev.cargo.site/_api/v1-p';
		INTERCOM_APP_ID = 'na91uuyv';

	break;
	case 'dev':

		WEB_HOSTNAME = 'dev.cargo.site';
		CRDT_SERVER_ORIGIN = 'wss://ws.dev.cargo.site';
		HOMEPAGE_ORIGIN = 'https://dev.cargo.site';
		API_ORIGIN = 'https://api.dev.cargo.site/v1';
		C2_API_ORIGIN = 'https://dev.cargo.site/_api';
		AUTH_ORIGIN = 'https://dev.cargo.site';
		API_PROXY_ORIGIN = 'https://dev.cargo.site/_api/v1-p';
		INTERCOM_APP_ID = 'na91uuyv';

	break;

}

export {
	WEB_HOSTNAME,
	CRDT_SERVER_ORIGIN,
	HOMEPAGE_ORIGIN,
	API_ORIGIN,
	C2_API_ORIGIN,
	AUTH_ORIGIN,
	API_PROXY_ORIGIN,
	INTERCOM_APP_ID
}